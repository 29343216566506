import $scope from 'consts/scope';
const _ = require('lodash');

export function getExchangePrettyName(exchange) {
  
  let subExchange = exchange;
  let hasHash = _.includes(exchange, "#");
  
  if (hasHash) {
    subExchange = exchange.split("#")[0];
  }
  
  const paramInfo = $scope.setupWizard.exchange.settings.parameters;
  const allExchanges = [...paramInfo.exchangeName.values, ...paramInfo.exchangeNameMore.values, ...paramInfo.exchangeNameDefi.values]
  const exchangeInfo = allExchanges.filter(element => element.value === subExchange)


  let prettyName = exchangeInfo?.[0]?.name || subExchange
  
  if (hasHash) {
    let hashPart = exchange.split("#")[1];
    prettyName = prettyName ? `${prettyName} #${hashPart}` : subExchange + " #" + hashPart;
    console.log('case #', prettyName)
  }

  return prettyName;
}
